import React, { useEffect } from "react";
import { useSettings } from "../Networking/useSettings";
import { useNavigate } from "react-router-dom";
import styles from "../Home/styles.module.css";
import { useTranslation } from "react-i18next";
import homeStyles from "../Home/styles.module.css";
import { Col } from "antd";

const Maintenance = () => {
  const { shopUnderMaintenance } = useSettings();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!shopUnderMaintenance) {
      navigate("/");
    }
  }, [shopUnderMaintenance, navigate]);

  return (
    <>
      <Col style={{ paddingLeft: 24, paddingRight: 24 }}>
        <div className={styles.banner}>
          <img
            className={homeStyles.orderIcon}
            src={"/icons/dish.svg"}
            alt={"yummy dish"}
          />
          <h1 className={styles.titleCentered}>
            {t("Il nostro shop è in manutenzione. Riprova più tardi")}
          </h1>
          <img
            className={styles.image}
            src={"/images/generic-dishes.avif"}
            alt={"delicious Humamy dishes"}
          />
        </div>
      </Col>
    </>
  );
};

export default Maintenance;
