import { Dayjs } from "dayjs";
import { InvoiceData } from "../../../common/me";
import { ActionInfo, UserAction } from "../event";

export class MembershipCTASelected implements UserAction {
  type: string = "membership_cta_selected";
  actionInfos = {};
}

export class MembershipPaywallCTASelected implements UserAction {
  type: string = "membership_paywall_cta_selected";
  actionInfos = {};
}

export class MembershipPaywallDiscardedCTASelected implements UserAction {
  type: string = "membership_paywall_discarded_cta_selected";
  actionInfos = {};
}

export class NewOrderCTASelected implements UserAction {
  type: string = "create_new_order_cta_selected";
  actionInfos = {};
}

interface SimplifiedLineItem {
  productTitle: string;
  productId: string;
  productSKU: string;
  quantity: number;
}

interface OrderSaveCTASelectedActionInfo {
  orderSavedBoxName: string;
  orderSavedBoxPrice: number;
  orderSavedLineItems: SimplifiedLineItem[];
}

export class OrderCartOpened implements UserAction {
  type: string = "order_cart_opened";
  actionInfos = {};
}

export class OrderCartClosed implements UserAction {
  type: string = "order_cart_closed";
  actionInfos = {};
}

interface OrderCheckoutActionInfo extends ActionInfo {
  deliveryDate: Dayjs;
  deliveryTime?: string;
  draftOrderExists: boolean;
  draftOrderIdentical: boolean;
  invoiceData?: InvoiceData;
}

export class OrderCheckout implements UserAction {
  type: string = "order_checkout";
  actionInfos: OrderCheckoutActionInfo;

  constructor(actionInfo: OrderCheckoutActionInfo) {
    this.actionInfos = actionInfo;
  }
}

interface FillBoxCtaSelectedActionInfo {
  boxSize: number;
}

export class FillBoxCtaSelected implements UserAction {
  type: string = "fill_box_cta_selected";
  actionInfos: FillBoxCtaSelectedActionInfo;
  metaCustomEventName = "AddToCart";

  constructor(actionInfo: FillBoxCtaSelectedActionInfo) {
    this.actionInfos = actionInfo;
  }
}

export class OrderConfirmCtaSelected implements UserAction {
  type: string = "order_confirm_cta_selected";
  actionInfos = {};
  metaCustomEventName = "StartTrial";
}

interface MembershipToggleClickedActionInfo {
  setsMembershipInCart: boolean;
}

export class MembershipToggleClicked implements UserAction {
  type: string = "membership_toggle_clicked";
  actionInfos: MembershipToggleClickedActionInfo;

  constructor(actionInfo: MembershipToggleClickedActionInfo) {
    this.actionInfos = actionInfo;
  }
}

export class ShippingDetailsOpened implements UserAction {
  type: string = "shipping_details_opened";
  actionInfos = {};
}

export class AddressConfirmed implements UserAction {
  type: string = "address_confirmed";
  actionInfos = {};
}

export class DeliveryDateSelected implements UserAction {
  type: string = "delivery_date_selected";
  actionInfos = {};
}

export class TastingBoxSelected implements UserAction {
  type: string = "tasting_box_selected";
  actionInfos = {};
}

export class TastingBoxSkipped implements UserAction {
  type: string = "tasting_box_skipped";
  actionInfos = {};
}

export class ChangeSubscriptionProfileSelected implements UserAction {
  type: string = "change_subscription_profile_selected";
  actionInfos = {};
}

interface SubscriptionProfileSelectedActionInfo {
  subscriptionBoxFrequency: number;
  subscriptionBoxSize: number;
}

interface SubscriptionProfileEditedActionInfo {
  subscriptionIsActive: boolean;
  subscriptionBoxSize: number;
  subscriptionBoxFrequency: string;
  subscriptionNextBoxDeliveryDate: Dayjs;
}

export class SubscriptionProfileEdited implements UserAction {
  type: string = "subscription_profile_edited";
  actionInfos: SubscriptionProfileEditedActionInfo;

  constructor(actionInfo: SubscriptionProfileEditedActionInfo) {
    this.actionInfos = actionInfo;
  }
}

export class SubscriptionProfileSelected implements UserAction {
  type: string = "subscription_profile_selected";
  actionInfos: SubscriptionProfileSelectedActionInfo;

  constructor(actionInfo: SubscriptionProfileSelectedActionInfo) {
    this.actionInfos = actionInfo;
  }
}

interface ShopFilterActionInfo {
  filterName: string;
}

export class ShopFilterSelected implements UserAction {
  type: string = "shop_filter_selected";
  actionInfos: ShopFilterActionInfo;

  constructor(actionInfo: ShopFilterActionInfo) {
    this.actionInfos = actionInfo;
  }
}

export class ShopFilterDeselected implements UserAction {
  type: string = "shop_filter_deselected";
  actionInfos: ShopFilterActionInfo;

  constructor(actionInfo: ShopFilterActionInfo) {
    this.actionInfos = actionInfo;
  }
}

interface ShopSearchTypedActionInfos {
  searchQuery: string;
}

export class ShopSearchTyped implements UserAction {
  type: string = "shop_search_typed";
  actionInfos: ShopSearchTypedActionInfos;

  constructor(actionInfos: ShopSearchTypedActionInfos) {
    this.actionInfos = actionInfos;
  }
}

export class UnlimitedPaywallInfoSelected implements UserAction {
  type: string = "unlimited_paywall_info_selected";
  actionInfos = {};
}

export class UnlimitedPaymentCheckout implements UserAction {
  type: string = "unlimited_payment_checkout";
  actionInfos = {};
}

export class WaitlistCTASelected implements UserAction {
  type: string = "waitlist_cta_selected";
  actionInfos = {};
}

export type {
  OrderCheckoutActionInfo,
  OrderSaveCTASelectedActionInfo,
  SimplifiedLineItem,
  SubscriptionProfileEditedActionInfo,
  ShopFilterActionInfo,
  ShopSearchTypedActionInfos,
};
