import { Button, Form, Input, Modal, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useMe } from "../AppSetup/MeContext";
import useUATracker from "../Networking/Metrics/useUATracker";
import {
  RegistrationModalCompleted,
  RegistrationModalDisplayed,
} from "../Networking/Metrics/UserActions/Login";
import useApiClient, { RegistrationRequest } from "../Networking/useApiClient";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";

const options = [
  {
    value: "Youtube",
    label: "Pubblicità su Youtube",
  },
  {
    value: "Dottore/nutrizionista",
    label: "Dottore/nutrizionista",
  },
  {
    value: "Pubblicità su FB/IG",
    label: "Pubblicità su FB/IG",
  },
  {
    value: "Evento",
    label: "Evento",
  },
  {
    value: "Ci segue su FB/IG",
    label: "Ci segue su FB/IG",
  },
  {
    value: "Influencer/pagina",
    label: "Influencer/pagina",
  },
  {
    value: "Passaparola amico/a",
    label: "Passaparola amico/a",
  },
  {
    label: "Passaparola in ufficio",
    value: "Ufficio",
  },
  {
    value: "Trustpilot",
    label: "Trustpilot",
  },
  {
    label: "Cercando su Google",
    value: "Google",
  },
];

function shuffle<Type>(array: Type[]): Type[] {
  return array.sort(() => Math.random() - 0.5);
}

const RegistrationModal = () => {
  const me = useMe();

  const { t } = useTranslation();
  const { trackUserAction } = useUATracker();
  const { completeRegistration } = useApiClient();

  const [selectedSource, setSelectedSource] = useState<string | undefined>(
    undefined,
  );

  const [loading, setLoading] = useState(false);

  const translatedOptions = options.map((option) => ({
    ...option,
    label: t(option.label),
  }));

  const onFinish = (values: RegistrationRequest) => {
    setLoading(true);
    completeRegistration(values).then(() => {
      setLoading(false);
      trackUserAction(new RegistrationModalCompleted());
    });
  };

  useEffect(() => {
    if (me.shouldCompleteRegistration) {
      trackUserAction(new RegistrationModalDisplayed());
    }
  }, [me.shouldCompleteRegistration, trackUserAction]);

  return (
    <Modal
      width={"90%"}
      className={styles.alert}
      title={t("Completa la registrazione")}
      destroyOnClose
      open={me.shouldCompleteRegistration}
      footer={null}
      bodyStyle={{ height: "100%", paddingTop: 24 }}
      forceRender={true}
      closeIcon={null}
    >
      <Form
        layout={"vertical"}
        labelWrap
        labelAlign={"left"}
        onFinish={onFinish}
      >
        <Form.Item
          name="howDidYouFindUs"
          label={t("Come ci hai conosciuto?")}
          rules={[{ required: true }]}
        >
          <Select
            options={shuffle(translatedOptions)}
            onChange={(value) => {
              setSelectedSource(value as string);
            }}
          />
        </Form.Item>
        {selectedSource === "Influencer/pagina" && (
          <Form.Item
            name="influencerName"
            label={t("Nome dell'influencer o della pagina")}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        )}
        <Form.Item
          name="phone"
          label={t("Numero di Telefono")}
          rules={[
            {
              required: true,
              pattern: new RegExp("^\\+\\d+$"),
              message: t(
                "Numero non valido. Aggiungi il prefisso (con il +) ed evita spazi.",
              ),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="marketingConsent"
          labelAlign={"left"}
          label={t(
            "Vuoi ricevere comunicazioni su promozioni, sconti, e altre offerte?",
          )}
          rules={[{ required: true }]}
        >
          <Select
            options={[
              {
                label: t("Si"),
                value: true,
              },
              {
                label: t("No"),
                value: false,
              },
            ]}
          />
        </Form.Item>
        {loading ? (
          <Spin />
        ) : (
          <Form.Item wrapperCol={{ span: 24 }} style={{ marginBottom: 0 }}>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                fontWeight: "bold",
                fontSize: 16,
                height: 60,
                width: "100%",
              }}
            >
              {t("Completa la registrazione")}
            </Button>
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default RegistrationModal;
