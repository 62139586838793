import { useEffect } from "react";

const HubspotChat = () => {
  useEffect(() => {
    window.hsConversationsSettings = {
      loadImmediately: true,
    };

    let script = document.createElement("script");
    script.src = `//js.hs-scripts.com/26270116.js`;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
      window.hsConversationsOnReady = [];
    };
  }, []);

  return <></>;
};

export default HubspotChat;
