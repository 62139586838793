import { useEffect, useState } from "react";
import useApiClient from "../Networking/useApiClient";
import { DelegateCommandStatus, DelegatedCommand } from "./models";

export const useDelegatedCommand = ({
  traceId,
}: {
  traceId: string | undefined;
}) => {
  const [delegatedCommand, setDelegatedCommand] = useState<
    DelegatedCommand | null | undefined
  >(undefined);
  const { getDelegatedCommand } = useApiClient();

  useEffect(() => {
    if (!traceId) {
      return;
    }
    const intervalId = setInterval(() => {
      getDelegatedCommand({ traceId })
        .then((response) => {
          setDelegatedCommand(response);
          if (
            response?.status === DelegateCommandStatus.Finished ||
            response === null
          ) {
            clearInterval(intervalId);
          }
        })
        .catch(() => {
          clearInterval(intervalId);
        });
    }, 3000);

    return () => clearInterval(intervalId);
  }, [traceId, getDelegatedCommand]);

  return { delegatedCommand };
};
