import React, { useContext, useState } from "react";
import { Button, Col, Row } from "antd";
import { CartContext } from "../../Cart/CartContext";
import styles from "./styles.module.css";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import AddressModal from "../../AddressModal/AddressModal";
import { useMe } from "../../AppSetup/MeContext";
import InvoiceForm from "../../Profile/InvoiceForm";
import { useSettings } from "../../Networking/useSettings";
import { useTranslation } from "react-i18next";

export const Shipping = () => {
  const me = useMe();
  const { subsEnabled, improvedShippingFlow, unlimitedEnabled } = useSettings();
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();

  const toggleModal = () => setShowModal(!showModal);

  // Prevent scrolling if modal is opened
  if (showModal) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "unset";
  }

  const cart = useContext(CartContext);
  if (!cart) {
    throw new Error("Recap can only be used inside a valid CartContext");
  }

  let key = "InsertShippingAddress";
  if (me?.shippingInfo.address) {
    key = "ConfirmShippingAddress";
  }

  const ButtonCTAKey =
    (me?.shippingInfo?.address?.address1 ? "Modifica " : "Inserisci ") +
    "Indirizzo";

  return (
    <Row justify="center">
      <Col span={8} xs={24} md={12} xl={8} style={{ padding: "0 24px" }}>
        {subsEnabled || unlimitedEnabled ? (
          <>
            <div className={styles.titleCentered}>{t("Indirizzo")}</div>
            <p className={styles.text}>{t(key)}</p>
          </>
        ) : (
          !improvedShippingFlow && <p className={styles.body}>{t(key)}</p>
        )}

        <Row>
          <Col span={24}>
            {me?.shippingInfo.address?.address1 ? (
              <>
                <div className={styles.infoPack}>
                  <span className={styles.inputValue}>
                    {me?.firstName} {me?.lastName}
                  </span>
                  <span className={styles.inputValue}>
                    {me?.shippingInfo.address?.address1}
                  </span>
                  <span className={styles.inputValue}>
                    {me?.shippingInfo.address?.city} (
                    {me?.shippingInfo.address?.province})
                  </span>
                  <span className={styles.inputValue}>
                    {me?.shippingInfo.address?.zip}
                  </span>
                </div>
                {!(unlimitedEnabled && me.latestOrder) ? (
                  <Button
                    className={styles.button}
                    type={"text"}
                    onClick={toggleModal}
                  >
                    {t("Modifica Indirizzo")}
                    <EditOutlined className={styles.buttonIcon} />
                  </Button>
                ) : null}
              </>
            ) : improvedShippingFlow ? (
              <>
                <Button
                  className={styles.button + " " + styles.primary}
                  type={"text"}
                  onClick={toggleModal}
                >
                  {t("Inserisci Indirizzo")}
                  <PlusOutlined className={styles.buttonIcon} />
                </Button>
                {unlimitedEnabled && !me.latestOrder ? (
                  <div
                    className={styles.noteWithIcon}
                    style={{ marginTop: 16 }}
                  >
                    <img src={"/icons/alert.svg"} alt={"attention"} />
                    {t(
                      "Attenzione! Non potrai più modificare l'indirizzo dopo il primo ordine.",
                    )}
                  </div>
                ) : null}
              </>
            ) : (
              <Button
                className={styles.button}
                type={"text"}
                onClick={toggleModal}
              >
                {t(ButtonCTAKey)}
                <EditOutlined className={styles.buttonIcon} />
              </Button>
            )}

            <InvoiceForm />
            <AddressModal
              open={showModal}
              onOk={toggleModal}
              onCancel={toggleModal}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
