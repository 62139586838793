const dayjs = require("dayjs");

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

const strCamelToSnakeCase: (str: string) => string = (str: string) => {
  if (str.length === 0) return str;
  return (
    str[0].toLowerCase() +
    str.substr(1).replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
  );
};

export const camelToSnakeCase = (obj: any): any => {
  if (obj instanceof dayjs) return obj.format("YYYY-MM-DD");
  if (obj instanceof Date) return obj.toISOString();
  if (obj === null || obj === undefined || typeof obj === "string") return obj;
  if (Array.isArray(obj))
    return obj.map((element) => camelToSnakeCase(element));

  const res: { [key: string]: any } = {};

  for (const [propertyName, value] of Object.entries(obj)) {
    const snakeCasePropertyName = strCamelToSnakeCase(propertyName);
    const valueIsComplexObject = typeof value === "object" && value !== null;
    res[snakeCasePropertyName] = valueIsComplexObject
      ? camelToSnakeCase(value)
      : value;
  }

  return res;
};

const strSnakeToCamelCase: (str: string) => string = (str: string) => {
  return str
    .split("_")
    .map((s, i) => {
      if (s.length > 0 && i > 0) {
        return s[0].toLocaleUpperCase() + s.substr(1);
      }
      return s;
    })
    .join("");
};

export const snakeToCamelCase = <T>(obj: any): T => {
  if (obj === null || obj === undefined || typeof obj === "string") return obj;
  if (Array.isArray(obj))
    // @ts-ignore
    return obj.map((element) => snakeToCamelCase(element));

  const res: { [key: string]: any } = {};

  for (const [propertyName, value] of Object.entries(obj)) {
    const camelCasePropertyName = strSnakeToCamelCase(propertyName);
    const valueIsComplexObject = typeof value === "object" && value !== null;
    res[camelCasePropertyName] = valueIsComplexObject
      ? snakeToCamelCase(value)
      : value;
  }

  return res as T;
};


export const capitalize = (str: string): string => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}