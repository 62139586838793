import {
  SettingsContext,
  useSettings,
  useSettingsFromBackend,
} from "../Networking/useSettings";
import { Spin } from "antd";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import React, { useEffect } from "react";
import Home from "../Home/Home";
import { Recap } from "../Shop/Recap/Recap";
import { Shipping } from "../Shop/Shipping/Shipping";
import SelectSubscription from "../Shop/Subscription/SelectSubscription";
import SubscriptionRecap from "../Shop/Subscription/SubscriptionRecap";
import OrderRecap from "../Shop/Checkout/OrderRecap";
import SelectShippingDate from "../Shop/Shipping/SelectShippingDate";
import Profile from "../Profile/Profile";
import PastOrders from "../Profile/PastOrders";
import FAQ from "../FAQ/FAQ";
import AppSetupLogin from "./AppSetupLogin";
import ShopLoader from "../Shop/ShopLoader";
import ProductsView from "../ProductsView/ProductsView";
import CookieList from "../CookieList/CookieList";
import Paywall from "../Shop/Paywall";
import OneClickPurchase from "../OneClickPurchase/OneClickPurchase";
import HumamyMenu from "../HumamyMenu/HumamyMenu";
import { TastingBox } from "../Shop/TastingBox/TastingBox";
import { MembershipCheckout } from "../OneClickPurchase/MembershipCheckout";
import { useOpenInNewTab } from "../Hooks/useRedirect";
import { useTranslation } from "react-i18next";
import { StripeBase } from "../StripeCheckout/StripeBase";
import UnlimitedPaywall from "../Shop/Unlimited/UnlimitedPaywall";
import UnlimitedRecap from "../Shop/Unlimited/UnlimitedRecap";
import { PaymentSucceeded } from "../StripeCheckout/PaymentSucceeded";
import WaitingList from "../WaitingLists/WaitingList";
import WaitingListRecap from "../WaitingLists/WaitingListRecap";
import Maintenance from "../Maintenance/Maintenance";

interface RedirectProps {
  url: string;
}

const Redirect = ({ url }: RedirectProps) => {
  const openInNewTab = useOpenInNewTab();
  useEffect(() => {
    openInNewTab(url);
  }, [openInNewTab, url]);
  return <Spin />;
};

const PrivacyPolicyRedirect = () => {
  const { t } = useTranslation();
  return <Redirect url={t("privacyPolicyUrl")} />;
};

const TermsAndConditionsRedirect = () => {
  const { t } = useTranslation();
  return <Redirect url={t("termsAndConditionsUrl")} />;
};

const RedirectToSupport = () => {
  const { supportUrl } = useSettings();
  return <Redirect url={supportUrl} />;
};

const router = createBrowserRouter([
  {
    path: "/one-click-purchase",
    element: <OneClickPurchase />,
  },
  {
    path: "/menu",
    element: <HumamyMenu />,
  },
  {
    path: "/menu-de",
    element: <HumamyMenu collectionId={"620676743500"} />,
  },
  {
    path: "/",
    element: <AppSetupLogin />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/products",
        element: <ProductsView editable={false} />,
      },
      {
        path: "/membership-checkout/:membershipSku/:sellingPlanId?",
        element: <MembershipCheckout />,
      },
      {
        path: "/unlimited-recap",
        element: <UnlimitedRecap />,
      },
      {
        path: "/waiting-list-recap",
        element: <WaitingListRecap />,
      },
      {
        path: "/shop",
        element: <ShopLoader />,
        children: [
          {
            path: "/shop/edit-subscription",
            element: <Redirect url={"/shop/choose-subscription"} />,
          },
          {
            path: "/shop/edit-subscription-date",
            element: <Redirect url={"/shop/shipping-select-date"} />,
          },
          {
            path: "/shop/choose-subscription",
            element: <SelectSubscription />,
          },
          {
            path: "/shop/subscription-recap",
            element: <SubscriptionRecap />,
          },
          {
            path: "/shop/tasting-box",
            element: <TastingBox />,
          },
          {
            path: "/shop/recap",
            element: <Recap />,
          },
          {
            path: "/shop/paywall",
            element: <Paywall />,
          },
          {
            path: "/shop/shipping",
            element: <Shipping />,
          },
          {
            path: "/shop/shipping/select-date",
            element: <SelectShippingDate />,
          },
          {
            path: "/shop/edit",
            element: <Recap edit />,
          },
          {
            path: "/shop/stripe/checkout",
            element: <StripeBase />,
          },
          {
            path: "/shop/stripe/checkout/succeeded",
            element: <PaymentSucceeded />,
          },
          {
            path: "/shop/order-recap",
            element: <OrderRecap />,
          },
          {
            path: "/shop/unlimited-paywall",
            element: <UnlimitedPaywall />,
          },
          {
            path: "/shop/waiting-list",
            element: <WaitingList />,
          },
          {
            path: "/shop/maintenance",
            element: <Maintenance />,
          },
        ],
      },
      {
        path: "/faq",
        element: <FAQ />,
      },
      {
        path: "/profile",
        element: <Profile />,
      },
      {
        path: "/support",
        element: <RedirectToSupport />,
      },
      {
        path: "/profile/orders",
        element: <PastOrders />,
      },
      {
        path: "/cookie-list",
        element: <CookieList />,
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicyRedirect />,
      },
      {
        path: "/termini-uso",
        element: <TermsAndConditionsRedirect />,
      },
    ],
  },
]);

const AppSetupSettings = () => {
  const settings = useSettingsFromBackend();
  if (!settings) {
    return <Spin />;
  }
  return (
    <SettingsContext.Provider value={settings}>
      <RouterProvider router={router} />
    </SettingsContext.Provider>
  );
};

export default AppSetupSettings;
