import { Dayjs } from "dayjs";

export enum DelegateCommandStatus {
  NotStarted = "not_started",
  Started = "started",
  Finished = "finished",
  Failed = "failed",
}

interface DelegatedCommand {
  traceId: string;
  status: DelegateCommandStatus;
  message?: string;
  updatedAt: Dayjs;
}

export type { DelegatedCommand };
